export const CUSTOM_TYPES = {
  // this is only needed for the breadcrumbs to work
  // the about us root page does not exist
  // and it's just a redirect to one of the child pages
  'about-us-root': {
    url: '/about',
    title: 'About',
  },
  'about-us': {
    url: '/about/our-story',
    title: 'Our story',
  },
  approach: {
    url: '/about/approach',
    title: 'Our Approach',
  },
  awards: {
    url: '/about/awards',
    title: 'Awards',
  },
  team: {
    url: '/about/team',
    title: 'Team',
  },
  partners: {
    url: '/about/partners',
    title: 'Partners',
  },
  customer_stories: {
    url: '/about/customer-stories',
    title: 'Customer stories',
  },
  net_zero: {
    url: '/about/net-zero',
    title: 'Net Zero',
  },
}
